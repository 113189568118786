import _ from 'lodash';

export const strict = false;
export const state = () => {
  return {
    lang: localStorage.getItem("lang") || process.env.DEFAULT_LOCALE,
    headerBase: [
      { to: "/base", name: "header.mainPage" },
      { to: "/base/service/list", name: "header.services" },
      { to: "/base/geoportal", name: "header.geoportal" },
    ],
    headerArm: [
      { to: "/arm", name: "header.incoming" },
      { to: "/arm/service/list", name: "header.services" },
      { to: "/arm/geoportal", name: "header.geoportal" },
    ],
    userMenuBase: [
      { to: "/base/auth/profile", name: "userMenu.profile" },
      { to: "/base/application/list", name: "userMenu.history" },
    ],
    userMenuArm: [
      { to: "/arm/profile", name: "userMenu.profile", condition: 'always' },
      { to: "/arm/application/list", name: "userMenu.history", condition: 'always' },
      { to: "/arm/admin/org/employee", name: "admin.menu.employees", condition: 'orgAdmin' },
      { to: "/arm/admin/org/service", name: "admin.menu.wses", condition: 'orgAdmin' },
      { to: "/arm/admin/system/list", name: "admin.admins.title", condition: 'systemAdmin' },
      { to: "/arm/admin/system/app", name: "admin.apps.title", condition: 'systemAdmin' },
      { to: "/arm/admin/system/route", name: "admin.route", condition: 'systemAdmin' },
      { to: "/arm/admin/system/control", name: "admin.menu.control", condition: 'systemAdmin' },
      { to: "/arm/admin/system/layers", name: "admin.menu.layers", condition: 'systemAdmin' },
    ],
  }
};

export const mutations = {
  set_lang(state, lang) {
    state.lang = lang;
    localStorage.setItem("lang", lang)
  },

  setBaseHeaderMenuItem(state, item) {
    state.headerBase.push(item);
  },

  setArmHeaderMenuItem(state, item) {
    state.headerArm.push(item);
  },

  setBaseUserMenuItem(state, item) {
    if (item.order === undefined) {
      item.order = state.userMenuBase.length;
    }

    state.userMenuBase.splice(item.order, 0, item);
  },

  setArmUserMenuItem(state, item) {
    if (item.order === undefined) {
      item.order = state.userMenuArm.length;
    }

    state.userMenuArm.splice(item.order, 0, item);
  },
};

export const getters = {
  get_lang: (state) => {
    return state.lang;
  },

  allowedBaseUserMenuItems: (state) => {
    return state.userMenuBase;
  },

  allowedArmUserMenuItems: (state) => {
    return state.userMenuArm.filter((item) => {
      if (_.isFunction(item.condition)) {
        return item.condition(state.auth.user);
      }

      switch (item.condition) {
        case 'orgAdmin':
          return state.auth.user?.is_org_admin;
        case 'systemAdmin':
          return state.auth.user?.is_system_admin;
        case 'always':
        default:
          return true;
      }
    });
  }
};

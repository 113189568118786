import _ from 'lodash';
import {
  SET_LAYERS_LOADING,
  SET_LAYERS_LIST,
  SET_REGISTRIES_LOADING,
  SET_REGISTRIES_LIST,
  SET_SELECTED_REGISTRY,
  SET_LAYER
} from './mutation-types';

export default {
  [SET_LAYERS_LOADING](state, isLoading) {
    state.layersLoading = isLoading;
  },
  [SET_LAYERS_LIST](state, layersList) {
    state.layersList = layersList;
  },
  [SET_REGISTRIES_LOADING](state, isLoading) {
    state.registriesLoading = isLoading;
  },
  [SET_REGISTRIES_LIST](state, registriesList) {
    state.registriesList = registriesList;
  },
  [SET_SELECTED_REGISTRY](state, registryId) {
    state.selectedRegistry = _.find(
      state.registriesList,
      reg => reg.id === registryId
    );
  },
  [SET_LAYER](state, layer) {
    state.layer = layer;
  }
}

import {
  GET_LAYERS_LIST,
  GET_REGISTRIES_LIST,
  GET_LAYER_DATA,
  CREATE_LAYER,
  EDIT_LAYER,
  DELETE_LAYER,
} from "./action-types";
import {
  SET_LAYERS_LOADING,
  SET_LAYERS_LIST,
  SET_REGISTRIES_LOADING,
  SET_REGISTRIES_LIST,
  SET_LOADING,
  SET_LAYER,
} from "./mutation-types";

import _ from "lodash";

export default {
  [GET_LAYERS_LIST]({ commit }) {
    commit(SET_LAYERS_LOADING, true);

    return this.$axios
      .$get("api/registry/v2/layers/list")
      .then((response) => {
        commit(SET_LAYERS_LIST, response);

        return response;
      })
      .catch(() => alert("Ошибка загрузки данных!"))
      .finally(() => commit(SET_LAYERS_LOADING, false));
  },
  [GET_REGISTRIES_LIST]({ commit }) {
    commit(SET_REGISTRIES_LOADING, true);

    return this.$axios
      .$get("api/registry/v2/tables/list")
      .then((response) => {
        commit(SET_REGISTRIES_LIST, response);

        return response;
      })
      .catch(() => alert("Ошибка загрузки данных!"))
      .finally(() => commit(SET_REGISTRIES_LOADING, false));
  },
  [GET_LAYER_DATA]({ commit }, { layer_id }) {
    commit(SET_LOADING, true);

    return this.$axios
      .$get("api/registry/v2/layers/get", { params: { layer_id } })
      .then((response) => {
        commit(SET_LAYER, response);

        return response;
      })
      .catch(() => alert("Ошибка загрузки данных!"))
      .finally(() => commit(SET_LOADING, false));
  },
  [CREATE_LAYER]({ dispatch }, layerParams) {
    return this.$axios
      .$post("api/registry/v2/layers/create", layerParams)
      .then(() => dispatch(GET_LAYERS_LIST));
  },
  [EDIT_LAYER]({ dispatch }, layer) {
    return this.$axios
      .$post("api/registry/v2/layers/update", layer)
      .then(() => dispatch(GET_LAYERS_LIST));
  },
  [DELETE_LAYER]({ dispatch }, layer) {
    return this.$axios
      .$post("api/registry/v2/layers/delete", { layer_id: layer.id })
      .then(() => dispatch(GET_LAYERS_LIST));
  }
};

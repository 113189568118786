

export default () => ({
  layersLoading: false,
  layersList: [],
  registriesLoading: false,
  registriesList: [],
  selectedRegistry: null,
  layer: null,
  loading: false,
});

import { OSM, Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";

export default () => ({
  map: null,
  layersLoading: false,
  basemaps: {
    OSM: {
      olLayer: new TileLayer({
        source: new OSM(),
      }),
    }
  },
  activeBasemap: null,
  layers: null,
  activeLayers: [],
  panelExpanded: false,
  panelTab: null,
  detailsLoading: false,
  detailRequestController: null,
  objectDetails: {
    id: null,
    layer: null,
    attributes: null,
  },
  objectDetailSource: null,
});

import Map from "ol/Map.js";
import View from "ol/View.js";
import { OSM, Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { transform } from "ol/proj";

export default ({
  el,
  basemap,
  initialFocus = {
    zoom: 11,
    center: ["71.446", "51.1801"],
  },
}) =>
  new Map({
    layers: [
      basemap ??
        new TileLayer({
          source: new OSM(),
        }),
    ],
    target: el,
    view: new View({
      zoom: initialFocus.zoom,
      center: transform(
        initialFocus.center,
        "EPSG:4326",
        initialFocus.projection || "EPSG:3857"
      ),
      projection: initialFocus.projection
    }),
  });

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21eb9780 = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))
const _3c8b04c3 = () => interopDefault(import('../uicore/pages/base/index.vue' /* webpackChunkName: "pages/base/index" */))
const _662607a1 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _42986316 = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _0ee5376e = () => interopDefault(import('../uicore/pages/arm/geoportal/index.vue' /* webpackChunkName: "pages/arm/geoportal/index" */))
const _28d98ad2 = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _4edbc757 = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _a2353172 = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _06bb2cf1 = () => interopDefault(import('../uicore/pages/base/geoportal/index.vue' /* webpackChunkName: "pages/base/geoportal/index" */))
const _a698000c = () => interopDefault(import('../uicore/pages/base/registries.vue' /* webpackChunkName: "pages/base/registries" */))
const _e30284ea = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _0b207335 = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _6283b08a = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _d8984982 = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _d2c6d3c8 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _b2117e68 = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _43c22321 = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _56f2b561 = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _4971d3ca = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _968a4738 = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _e1d5660a = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _73e6b178 = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _7ba87c43 = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _355b0d0c = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _54315702 = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _08545a90 = () => interopDefault(import('../uicore/pages/arm/admin/system/layers.vue' /* webpackChunkName: "pages/arm/admin/system/layers" */))
const _06a09d6c = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _3db8e8ad = () => interopDefault(import('../uicore/pages/arm/admin/system/registryLayers.vue' /* webpackChunkName: "pages/arm/admin/system/registryLayers" */))
const _230785eb = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _69469bc6 = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _0b207c28 = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _73c4065f = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _09f93565 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _29bfd5d3 = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _8385844a = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _50ffc8b8 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _64935e22 = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _5df66070 = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _802728c4 = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _157cfc61 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _608997a7 = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _17269be8 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _da6c81aa = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _b0e7fc8e = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _0002f01a = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/arm",
    component: _21eb9780,
    name: "arm"
  }, {
    path: "/base",
    component: _3c8b04c3,
    name: "base"
  }, {
    path: "/Test",
    component: _662607a1,
    name: "Test"
  }, {
    path: "/arm/forgotPass",
    component: _42986316,
    name: "arm-forgotPass"
  }, {
    path: "/arm/geoportal",
    component: _0ee5376e,
    name: "arm-geoportal"
  }, {
    path: "/arm/login",
    component: _28d98ad2,
    name: "arm-login"
  }, {
    path: "/arm/profile",
    component: _4edbc757,
    name: "arm-profile"
  }, {
    path: "/base/faq",
    component: _a2353172,
    name: "base-faq"
  }, {
    path: "/base/geoportal",
    component: _06bb2cf1,
    name: "base-geoportal"
  }, {
    path: "/base/registries",
    component: _a698000c,
    name: "base-registries"
  }, {
    path: "/oldAppsList/applicant",
    component: _e30284ea,
    name: "oldAppsList-applicant"
  }, {
    path: "/oldAppsList/employee",
    component: _0b207335,
    name: "oldAppsList-employee"
  }, {
    path: "/arm/application/income",
    component: _6283b08a,
    name: "arm-application-income"
  }, {
    path: "/arm/application/list",
    component: _d8984982,
    name: "arm-application-list"
  }, {
    path: "/base/application/list",
    component: _d2c6d3c8,
    name: "base-application-list"
  }, {
    path: "/base/auth/forgotPass",
    component: _b2117e68,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/auth/login",
    component: _43c22321,
    name: "base-auth-login"
  }, {
    path: "/base/auth/profile",
    component: _56f2b561,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/register",
    component: _4971d3ca,
    name: "base-auth-register"
  }, {
    path: "/arm/admin/org/app",
    component: _968a4738,
    name: "arm-admin-org-app"
  }, {
    path: "/arm/admin/org/employee",
    component: _e1d5660a,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/service",
    component: _73e6b178,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/system/app",
    component: _7ba87c43,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/system/calendar",
    component: _355b0d0c,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/control",
    component: _54315702,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/layers",
    component: _08545a90,
    name: "arm-admin-system-layers"
  }, {
    path: "/arm/admin/system/list",
    component: _06a09d6c,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/registryLayers",
    component: _3db8e8ad,
    name: "arm-admin-system-registryLayers"
  }, {
    path: "/arm/admin/system/route",
    component: _230785eb,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/application/view/asId",
    component: _69469bc6,
    name: "arm-application-view-asId"
  }, {
    path: "/base/application/result/appId",
    component: _0b207c28,
    name: "base-application-result-appId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _73c4065f,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _09f93565,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _29bfd5d3,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _8385844a,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _50ffc8b8,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _64935e22,
    name: "arm-service-list-parentId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _5df66070,
    name: "base-application-monitor-appId"
  }, {
    path: "/base/application/process/:asId?",
    component: _802728c4,
    name: "base-application-process-asId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _157cfc61,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _608997a7,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/service/list/:parentId?",
    component: _17269be8,
    name: "base-service-list-parentId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _da6c81aa,
    name: "arm-resetPass-token"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _b0e7fc8e,
    name: "base-check_doc-fileId"
  }, {
    path: "/",
    component: _0002f01a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      mainMenu: false,
      authMenu: false,
    };
  },
  created() {
    document.addEventListener("click", this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClickOutside);
  },
  computed: {
    ...mapState({ menuItems: 'headerBase' }),
    ...mapGetters({ authMenuItems: 'allowedBaseUserMenuItems' }),
    availableLocales() {
      return this.$i18n.locales;
    },
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      this.$router.push("/base/auth/login");
    },
    redirectToLogin() {
      this.$router.push({
        path: "/base/auth/login",
        query: { redirect: this.$route.fullPath },
      });
    },
    changeLocale(newLocale) {
      this.$i18n.setLocale(newLocale);
      this.$nuxt.$emit("changeLocale", newLocale);
    },
    onClickOutside: (e) => {
      this.authMenu = this.$el.contains(e.target) && this.authMenu;
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      projectName: process.env.projectName,
      projectEmail: process.env.PROJECT_EMAIL,
      projectPhone: process.env.PROJECT_PHONE,
    };
  },
};
